@font-face {
  font-family: 'Flama';
  src: url('flama-book.woff2') format('woff2'),
    url('flama-book.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Flama';
  src: url('flama-book-italic.woff2') format('woff2'),
    url('flama-book-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
