@font-face {
  font-family: Flama;
  src: url("flama-book.5598e9e2.woff2") format("woff2"), url("flama-book.9f9ce907.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-book-italic.0ccbb903.woff2") format("woff2"), url("flama-book-italic.9682dfea.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-bold.510ee5fe.woff2") format("woff2"), url("flama-bold.7b43e46d.woff") format("woff");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-bold-italic.ee1a5146.woff2") format("woff2"), url("flama-bold-italic.544f8b46.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-light.d141815b.woff2") format("woff2"), url("flama-light.79a961be.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-light-italic.52342470.woff2") format("woff2"), url("flama-light-italic.ff60d781.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-medium.bc72abcb.woff2") format("woff2"), url("flama-medium.ed7d02be.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-medium-italic.663ad53b.woff2") format("woff2"), url("flama-medium-italic.d9548250.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-semibold.6d3fae20.woff2") format("woff2"), url("flama-semibold.e0de9516.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Flama;
  src: url("flama-semibold-italic.653e5e85.woff2") format("woff2"), url("flama-semibold-italic.01c34ebb.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
/*# sourceMappingURL=flama.css.map */
