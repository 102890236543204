@font-face {
  font-family: 'Flama';
  src: url('flama-semibold.woff2') format('woff2'),
    url('flama-semibold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Flama';
  src: url('flama-semibold-italic.woff2') format('woff2'),
    url('flama-semibold-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
